<template>
  <div>
    <LogsFilter />
    <LogsTable />
  </div>
</template>

<script>

import LogsTable from "@/views/apps/logs/components/LogsTable.vue";
import LogsFilter from "@/views/apps/logs/components/LogsFilter.vue";
export default {
  name: "LogsView",

  components: {
    LogsTable,
    LogsFilter,
  }
}
</script>