<template>
  <div>
    <p><b>Відправлено пуш повідомлення: </b>{{ `"${actionData.payload.body}"` }}</p>
    <p><b>Призначення: </b>{{ getTargetTypesTitle(actionData.payload.typeTarget) }}</p>
    <div v-if="actionData.payload.profiles">
      <p><b>Водії:</b></p>
      <b-link
          v-for="profile in actionData.payload.profiles"
          :key="profile.profileId"
          :href="`/apps/users/edit/${profile.profileId}`">
        {{ profile.fullName }}
      </b-link>
    </div>
    <div v-if="actionData.payload.groups">
      <p><b>Групи:</b></p>
      <b-link
          v-for="group in actionData.payload.groups"
          :key="group.groupId"
          :href="`/apps/group/view/${profile.group.groupId}`">
        {{ group.title }}
      </b-link>
    </div>
  </div>
</template>

<script>

import { BLink } from "bootstrap-vue";

import { useDiscountTargetTypes } from "@/composition/formOptions/useDiscountTargetTypes";

export default {
  name: "SendPushNotification",
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  components: {
    BLink,
  },
  setup() {
    const { getTargetTypesTitle } = useDiscountTargetTypes();

    return {
      getTargetTypesTitle,
    };
  },
};
</script>