<template>
  <b-card no-body>
    <LogsModal>
      <component v-if="modalTemplate" :is="modalTemplate" :actionData="selectedActionData" />
    </LogsModal>
    <b-skeleton-wrapper :loading="logsActionsLoading">
      <template #loading>
        <b-skeleton-table
            :rows="10"
            :columns="5"
            :table-props="{ bordered: true }"
        ></b-skeleton-table>
      </template>
      <b-table
          ref="refBusinessLogs"
          class="position-relative"
          :items="logsActions"
          v-bind="getTableAttributes"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Відповідних записів не знайдено"
      >
        <template #cell(createdAt)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.executedAt }}
          </div>
        </template>

        <template #cell(actionType)="data">
          <div class="font-weight-bold d-block text-nowrap d-flex justify-content-between" :class="getCellDirection">
            {{ getManagersActionsTypesTitle(data.item.typeAction) }}
            <b-button
                v-b-modal.logsModal
                @click="setModalContent(data.item)"
                variant="primary"
                size="sm"
                class="logs-btn"
            >
              Детальніше
            </b-button>
          </div>
        </template>

        <template #cell(profile)="data">
          <div class="font-weight-bold d-block">
            <b-link :href="`/apps/users/edit/${data.item.managerId}`">{{ getManagerName(data.item.managerId) }}</b-link>
          </div>
        </template>

        <template #cell(sessionId)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.sessionId }}
          </div>
        </template>

        <template #cell(role)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ getManagersRolesTitle(data.item.managerRole) }}
          </div>
        </template>=
      </b-table>
    </b-skeleton-wrapper>
    <LogsPagination />
  </b-card>
</template>

<script>

import { BTable, BCard, BButton, BLink, BSkeletonTable, BSkeletonWrapper, } from 'bootstrap-vue';
import LogsPagination from '@/views/apps/logs/components/LogsPagination.vue';
import LogsModal from '@/views/apps/logs/components/LogsModal.vue';

import UserLogin from '@/views/apps/logs/components/modalContent/UserLogin.vue';
import SendPushNotification from '@/views/apps/logs/components/modalContent/SendPushNotification.vue';
import ChangePushNotificationSettings
  from '@/views/apps/logs/components/modalContent/ChangePushNotificationSettings.vue';
import SendMoney from '@/views/apps/logs/components/modalContent/SendMoney.vue';
import CreateManager from '@/views/apps/logs/components/modalContent/CreateManager.vue';
import ChangeManager from '@/views/apps/logs/components/modalContent/ChangeManager.vue';
import CreateDriver from '@/views/apps/logs/components/modalContent/CreateDriver.vue';
import ChangeDriverStatus from '@/views/apps/logs/components/modalContent/ChangeDriverStatus.vue';
import CreateBundle from '@/views/apps/logs/components/modalContent/CreateBundle.vue';
import ChangeBundle from '@/views/apps/logs/components/modalContent/ChangeBundle.vue';
import ChangeBundleList from '@/views/apps/logs/components/modalContent/ChangeBundleList.vue';
import CreateDriverCard from '@/views/apps/logs/components/modalContent/CreateDriverCard.vue';
import ChangeDiscountsComposite from '@/views/apps/logs/components/modalContent/ChangeDiscountsComposite.vue';

import { useBusinessLogs } from '@/composition/businessLogs/useBusinessLogs';
import { useManagersActionsTypes } from '@/composition/formOptions/useManagersActionsTypes';
import {computed, onBeforeMount, ref} from '@vue/composition-api';
import { useManagersRoles } from '@/composition/formOptions/useManagersRoles';
import { useManagers } from '@/composition/businessUsers/useManagers';
import { useDrivers } from '@/composition/businessUsers/useDrivers';
import { useDriverGroups } from '@/composition/businessUsers/useDriverGroups';

export default {
  name: 'LogsTable',
  components: {
    BCard,
    BTable,
    BButton,
    BLink,
    BSkeletonTable,
    BSkeletonWrapper,
    LogsPagination,
    LogsModal,

    UserLogin,
    SendPushNotification,
    ChangePushNotificationSettings,
    SendMoney,
    CreateManager,
    ChangeManager,
    CreateDriver,
    ChangeDriverStatus,
    CreateBundle,
    ChangeBundle,
    ChangeBundleList,
    CreateDriverCard,
    ChangeDiscountsComposite,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    getTableAttributes() {
      return this.screenWidth < 768 ? { stacked: true } : { responsive: true };
    },
    getCellDirection() {
      return this.screenWidth < 768 ? 'cell-column cell-wrap' : '';
    },
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  setup() {
    const { fetchLogsActions, logsActions, logsActionsLoading } = useBusinessLogs();
    const { getManagersActionsTypesTitle } = useManagersActionsTypes();
    const { getManagersRolesTitle } = useManagersRoles();
    const { fetchManagers, getManagerName } = useManagers();
    const { fetchDrivers } = useDrivers();
    const { fetchDriverGroups } = useDriverGroups();

    const tableColumns = [
      {key: 'createdAt', label: 'Дата створення', sortable: false, tdClass: 'custom-media-cell'},
      {key: 'actionType', label: 'Дія', sortable: false, tdClass: 'custom-media-cell'},
      {key: 'profile', label: 'Профіль', sortable: false, tdClass: 'custom-media-cell'},
      {key: 'role', label: 'Роль', sortable: false, tdClass: 'custom-media-cell'},
      {key: 'sessionId', label: 'ID сесії', sortable: false, tdClass: 'custom-media-cell'},
    ];

    const actionTypeMap = {
      1: 'UserLogin',
      11: 'SendPushNotification',
      12: 'ChangePushNotificationSettings',
      21: 'SendMoney',
      41: 'CreateManager',
      42: 'ChangeManager',
      43: 'ChangeManager',
      51: 'CreateDriver',
      52: 'CreateDriver',
      53: 'ChangeDriverStatus',
      61: 'CreateBundle',
      62: 'ChangeBundle',
      63: 'ChangeBundle',
      64: 'ChangeBundleList',
      71: 'CreateDriverCard',
      72: 'CreateDriverCard',
      73: 'CreateDriverCard',
      74: 'CreateDriverCard',
      75: 'CreateDriverCard',
      91: 'ChangeDiscountsComposite',
    };

    let selectedActionData = ref({});

    const setModalContent = (contentData) => {
      selectedActionData.value = contentData;
    };

    const modalTemplate = computed(() => {
      const actionType = parseInt(selectedActionData.value.typeAction);
      return actionTypeMap[actionType] || null;
    });

    onBeforeMount(() => {
      fetchLogsActions({size: 25});
      fetchManagers();
      fetchDrivers();
      fetchDriverGroups();
    });

    return {
      logsActions,
      tableColumns,
      modalTemplate,
      selectedActionData,
      logsActionsLoading,
      getManagersActionsTypesTitle,
      getManagersRolesTitle,
      setModalContent,
      getManagerName,
    };
  },
};
</script>
<style type="text/css" scoped>
  .cell-column {
    flex-direction: column;
  }
  .cell-wrap {
    white-space: normal !important;
  }
  .logs-btn {
    margin-left: 1rem;
  }
</style>

<style type="scss">
  @media screen and (max-width: 768px) {
    .custom-media-cell {
      display: flex !important;
      align-items: center !important;
    }
    .logs-btn {
      margin-left: 0 !important;
    }
  }
</style>
