import {onBeforeMount, reactive, toRefs} from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const managersActionsTypesTranslations = {
    user_login: "Користувач авторизувався",
    send_push_notification: "Відправлено пуш повідомлення",
    change_push_notification_settings: "Змінено налаштування пуш повідомлення",
    send_money: "Відправлено гроші",
    create_manager: "Створено менеджера",
    change_manager: "Змінено менеджера",
    change_manager_status: "Змінено статус менеджера",
    create_driver: "Створено водія",
    change_driver: "Змінено водія",
    change_driver_status: "Змінено статус водія",
    create_bundle: "Створено групу",
    change_bundle: "Змінено групу",
    delete_bundle: "Видалено групу",
    change_bundle_list: "Змінено список групи",
    create_driver_card: "Створено картку водія",
    change_driver_card_status: "Змінено статус картки водія",
    change_driver_card_pin: "Змінено пін картки водія",
    assign_driver_card_limits: "Призначено ліміти картки водія",
    assign_driver_card_fuels: "Призначено паливо для картки водія",
    change_discount_composite: "Змінено шаблон знижок",
};
export function useManagersActionsTypes() {
    const data = reactive({
        managersActionsTypes: null,
    })

    const fetchManagersActionsTypes =  async () => {
        const response = await FormOptionsService.managersActionsTypes();
        response?.data.items?.forEach(el => {
            el.translated = managersActionsTypesTranslations[el.title]
        })
        data.managersActionsTypes = response?.data.items;
    };

    const getManagersActionsTypesTitle = (id) => {
        const title = data.managersActionsTypes?.find(el => el.value === id)?.title;
        return managersActionsTypesTranslations[title];
    }

    onBeforeMount(async() => {
        await fetchManagersActionsTypes();
    });

    return {getManagersActionsTypesTitle, ...toRefs(data)};

}