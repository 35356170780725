<template>
  <p>
    {{ `Змінено налаштування пуш повідомлення "${actionValues[actionData.payload.title]}" на ${actionData.payload.value ? "Включено" : "Виключено"}` }}
  </p>
</template>

<script>

export default {
  name: "ChangePushNotificationSettings",
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const actionValues = {
      onDiscountUpdate: "Оновлення знижки",
      onBundleUpdate: "Оновлення групи",
    }
    return { actionValues }
  }
}
</script>