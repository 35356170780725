<template>
  <div>
    <h4>Створено нову групу</h4>
    <p><b>Назва: </b>{{ actionData.payload.title }}</p>
    <p>
      <b>ID: </b>
      <b-link :href="`/apps/group/view/${actionData.payload.newBundleId}`">{{ actionData.payload.newBundleId }}</b-link>
    </p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

export default {
  name: "CreateBundle",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
};
</script>