<template>
  <div>
    <h4>Змінено менеджера</h4>
    <p><b>Ім'я: </b>{{ actionData.payload.fullName || "-" }}</p>
    <p><b>Роль: </b>{{ getManagersRolesTitle(actionData.payload.role) || "-" }}</p>
    <p>
      <b>ID: </b>
      <b-link :href="`/apps/users/edit/${actionData.payload.managerId}`">{{ actionData.payload.managerId }}</b-link>
    </p>
  </div>
</template>

<script>
import {BLink} from "bootstrap-vue";

import { useManagersRoles } from "@/composition/formOptions/useManagersRoles";

export default {
  name: "ChangeManager",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getManagersRolesTitle } = useManagersRoles();

    return {
      getManagersRolesTitle,
    };
  },
};
</script>

<style scoped>

</style>