<template>
  <div>
    <h4>Змінено статус водія</h4>
    <p>
      <b>Водій: </b>
      <b-link :href="`/apps/drivers/view/${actionData.payload.driverId}`">{{ getDriverName(actionData.payload.driverId) }}</b-link>
    </p>
    <p><b>Статус до: </b>{{ getDriversStatusesTitle(actionData.payload.statusBefore) }}</p>
    <p><b>Статус після: </b>{{ getDriversStatusesTitle(actionData.payload.statusAfter) }}</p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

import { useDrivers } from "@/composition/businessUsers/useDrivers";
import { useDriversStatuses } from "@/composition/formOptions/useDriversStatuses";
import { onBeforeMount } from "@vue/composition-api";

export default {
  name: "ChangeDriverStatus",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getDriverName } = useDrivers();
    const { fetchDriversStatuses, getDriversStatusesTitle } = useDriversStatuses();

    onBeforeMount(() => {
      fetchDriversStatuses();
    })

    return { getDriverName, getDriversStatusesTitle };
  }
};
</script>