import {onBeforeMount, reactive, toRefs} from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const managersRolesTranslations = {
    owner: "Власник",
    admin: "Адміністратор",
    supervisor: "Супервізор",
    manager: "Менеджер",
    support: "Підтримка",
};

export function useManagersRoles() {
    const data = reactive({
        managersRoles: null,
    })

    const fetchManagersRoles =  async () => {
        const response = await FormOptionsService.managersRoles();
        response?.data?.items?.forEach(el => {
            el.translated = managersRolesTranslations[el.title]
        })
        data.managersRoles = response?.data.items;
    };

    const getManagersRolesTitle = (title) => {
        return managersRolesTranslations[title];
    }

    onBeforeMount(async() => {
        await fetchManagersRoles();
    });

    return {getManagersRolesTitle, ...toRefs(data)};
}
