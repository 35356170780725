import BusinessUsersService from "@/services/businessUsers/businessUsers.service";

export const managersStoreModule = {
    namespaced: true,

    state: {
        managers: null,
        managersFetch: null,
    },

    actions: {
        fetchManagers({ state, commit }, params) {

            if(state.managersFetch) {
                return state.managersFetch;
            }

            let promise = BusinessUsersService.getManagers(params)
                .then(
                    (response) => {
                        commit("SET_MANAGERS", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_PROMISE", {promise: null, field: "managersFetch"}))

            commit("SET_PROMISE", {promise: promise, field: "managersFetch"})

            return promise;
        },
    },

    mutations: {
        SET_MANAGERS(state, data) {
            state.managers = data;
        },
        SET_PROMISE(state, {promise, field}) {
            state[field] = promise
        },
    },

    getters: {
        GET_MANAGERS(state) {
            return state.managers?.items;
        },
        GET_MANAGERS_BY_PROFILE_ID(state, getters) {
            return (getters.GET_MANAGERS || []).reduce((acc, manager) => {
                acc[manager.profileId] = manager
                return acc
            }, {})
        },
    },
};
