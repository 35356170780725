<template>
  <p>Користувач
    <b-link :href="`/apps/users/edit/${actionData.managerId}`">{{ getManagerName(actionData.managerId) }}</b-link>
    {{ `авторизувався ${actionData.executedAt}` }}
  </p>
</template>

<script>

import { BLink } from "bootstrap-vue";
import {useManagers} from "@/composition/businessUsers/useManagers";

export default {
  name: "UserLogin",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getManagerName } = useManagers();

    return { getManagerName }
  }
}
</script>