<template>
  <div>
    <h4>Створено нового менеджера</h4>
    <p><b>Ім'я: </b>{{ actionData.payload.fullName }}</p>
    <p><b>Телефон: </b>{{ actionData.payload.addedPhone }}</p>
    <p><b>Роль: </b>{{ actionData.payload.newManagerRole }}</p>
    <p>
      <b>ID: </b>
      <b-link :href="`/apps/users/edit/${actionData.payload.newManagerId}`">{{ actionData.payload.newManagerId }}</b-link>
    </p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

export default {
  name: "CreateManager",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
};
</script>