import { reactive, toRefs } from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const accountStatusesTranslations = {
    waiting_for_user_registration: "Очікується реєстрація користувача",
    account_processing: "Обробка",
    waiting_driver_pin_confirmation: "Очікується підтвердження PIN-коду",
    confirmed: "Підтверджено",
    pending_datex_creation: "Очікується створення DATEX",
    prepared: "Готовий",
    active: "Активний",
    blocked: "Заблокований",
};
export function useDriversStatuses() {
    const data = reactive({
        driversStatuses: null,
    })

    const fetchDriversStatuses =  async () => {
        const response = await FormOptionsService.driversStatuses();
        response?.data?.items?.forEach(el => {
            el.translated = accountStatusesTranslations[el.title]
        })
        data.driversStatuses = response?.data.items;
    };

    const getDriversStatusesTitle = (id) => {
        const title = data.driversStatuses?.find(el => el.id === id)?.title;
        return accountStatusesTranslations[title];
    }

    return { fetchDriversStatuses, ...toRefs(data), getDriversStatusesTitle };

}