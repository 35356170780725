<template>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Фільтри
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Дата</label>
            <b-form-datepicker
                v-model="filterExecutedAt"
                locale="UA"
                placeholder="Оберіть дату"
                @input="onFilterUpdate"
            />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Дія</label>
              <v-select
                  v-if="managersActionsTypes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="filterTypeAction"
                  :options="managersActionsTypes"
                  class="w-100"
                  label="translated"
                  :reduce="val => val.value"
                  placeholder="Оберіть дію"
                  @input="onFilterUpdate"
              />
              <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Профіль</label>
            <v-select
                v-if="managers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterProfileId"
                :options="managers"
                class="w-100"
                label="fullName"
                :reduce="val => val.profileId"
                placeholder="Оберіть профіль"
                @input="onFilterUpdate"
            />
            <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>Роль</label>
            <v-select
                v-if="managersRoles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="filterRole"
                :options="managersRoles"
                class="w-100"
                label="translated"
                :reduce="val => val.title"
                placeholder="Оберіть роль"
                @input="onFilterUpdate"
            />
            <b-skeleton v-else type="input" />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
          >
            <label>ID сесії</label>
            <b-form-input
                v-model="filterSessionId"
                type="number"
                placeholder="Введіть ID сесії"
                @change="onFilterUpdate"
            />
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="mb-md-0 my-2 d-flex align-items-end"
          >
            <b-button
                @click.prevent="resetFilter"
                variant="primary"
            >
              Скинути фільтр
            </b-button>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BButton, BFormInput, BSkeleton
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { onBeforeMount, reactive, toRefs } from "@vue/composition-api";
import { useBusinessLogs } from "@/composition/businessLogs/useBusinessLogs";
import { useManagersActionsTypes } from "@/composition/formOptions/useManagersActionsTypes";
import { useManagers } from "@/composition/businessUsers/useManagers";
import { useManagersRoles } from "@/composition/formOptions/useManagersRoles";

const initialFilterObject = {
  filterExecutedAt: null,
  filterTypeAction: null,
  filterProfileId: null,
  filterRole: null,
  filterSessionId: null,
  page: 1,
}
export default {
  name: "LogsFilter",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    BButton,
    BFormInput,
    BSkeleton,
    vSelect,
  },
  setup() {
    const { fetchLogsActions } = useBusinessLogs();
    const { managersActionsTypes } = useManagersActionsTypes();
    const { fetchManagers, managers } = useManagers();
    const { managersRoles } = useManagersRoles();

    const filterOptions = reactive({...initialFilterObject});

    const resetFilter = () => {
      Object.assign(filterOptions, initialFilterObject);
      fetchLogsActions(filterOptions)
    };

    const onFilterUpdate = async () => {
      await fetchLogsActions(filterOptions);
    };

    onBeforeMount(() => {
      fetchManagers();
    });

    return {
      managersActionsTypes,
      managers,
      managersRoles,

      onFilterUpdate,
      resetFilter,

      ...toRefs(filterOptions),
    }
  },
};
</script>
