<template>
  <div>
    <p>
      <b>Відправка коштів для: </b>
      <b-link :href="`/apps/drivers/view/${actionData.payload.driverId}`">{{ getDriverName(actionData.payload.driverId) }}</b-link>
    </p>
    <p><b>Рахунок: </b>{{ getAccountsTypesTitle(actionData.payload.typeAccount) }}</p>
    <div v-if="actionData.payload.transactions">
      <p><b>Транзакції:</b></p>
      <p
          v-for="transaction in actionData.payload.transactions"
          :key="transaction.id"
      >
        {{ transaction.id }}
        <span>{{ ` - Тип: ${transactionTypes[transaction.type]}` }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import {BLink} from "bootstrap-vue";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { useDrivers } from "@/composition/businessUsers/useDrivers";

export default {
  name: "SendMoney",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { getDriverName } = useDrivers();

    const transactionTypes = {
      1: "Поповнення",
      2: "Зняття",
    };

    return { getAccountsTypesTitle, transactionTypes, getDriverName, }
  },
};
</script>