<template>
  <b-modal
      id="logsModal"
      title="Деталі дії"
      hide-footer
  >
    <slot />
  </b-modal>
</template>

<script>
export default {
  name: "LogsModal",
}
</script>