<template>
  <div>
    <h4>Змінено список групи</h4>
    <p><b>Назва групи: </b>{{ actionData.payload.bundleTitle }}</p>
    <p>
      <b>ID групи: </b>
      <b-link :href="`/apps/group/view/${actionData.payload.bundleId}`">{{ actionData.payload.bundleId }}</b-link>
    </p>
    <p><b>Водій: </b>{{ actionData.payload.driverFullName }}</p>
    <p>
      <b>ID водія: </b>
      <b-link :href="`/apps/drivers/view/${actionData.payload.driverId}`">{{ actionData.payload.driverId }}</b-link>
    </p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

export default {
  name: "ChangeBundleList",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
};
</script>