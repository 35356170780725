<template>
  <div>
    <p><b>Ім'я: </b>{{ actionData.payload.driverFullName }}</p>
    <p><b>ID картки: </b>{{ actionData.payload.driverCardId }}</p>
    <p v-if="actionData.payload.pin"><b>ПІН: </b>{{ actionData.payload.pin }}</p>
    <p><b>Рахунок: </b>{{ getAccountsTypesTitle(actionData.payload.typeAccount) }}</p>
    <p v-show="actionData.payload.isActive"><b>Активна: </b>{{ actionData.payload.isActive ? "Так" : "Ні" }}</p>
    <p v-if="actionData.payload.fuels">
      <b>Паливо: </b>
      {{ actionData.payload.fuels.map(f => f.type).join(", ") }}
    </p>
    <p>
      <b>ID водия: </b>
      <b-link :href="`/apps/drivers/view/${actionData.payload.driverId}`">{{ actionData.payload.driverId }}</b-link>
    </p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";

export default {
  name: "CreateDriverCard",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getAccountsTypesTitle } = useAccountsTypes();

    return { getAccountsTypesTitle };
  },
};
</script>