<template>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
            v-if="logsPagination && logsPagination.total > 1"
            v-model="logsPagination.page"
            :total-rows="logsPagination.totalItems"
            :per-page="logsPagination.size"
            @change="handlePagination($event)"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BPagination } from "bootstrap-vue";

import { useBusinessLogs } from "@/composition/businessLogs/useBusinessLogs";

export default {
  name: "LogsPagination",
  components: {
    BRow,
    BCol,
    BPagination,
  },

  setup() {
    const { logsPagination, fetchLogsActions } = useBusinessLogs();

    const handlePagination = (page) => {
      const params = {
        page: page,
        size: logsPagination.value.size,
      };
      fetchLogsActions(params);
    };

    return { logsPagination, handlePagination };
  },
};
</script>