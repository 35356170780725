import BusinessLogsService from "@/services/businessLogs/businessLogs.service";

export const businessLogsModule = {
    namespaced: true,

    state: {
        logsActions: null,
        loadingLogsActions: true,
    },

    actions: {
        async fetchLogsActions({ state, commit }, params) {
            try {
                commit("SET_LOADING_LOGS_ACTIONS", true)
                const response = await BusinessLogsService.getLogsActions(params);
                commit("SET_LOGS_ACTIONS", response.data);
                commit("SET_LOADING_LOGS_ACTIONS", false)
            }
            catch(error) {
                commit("SET_LOADING_LOGS_ACTIONS", false)
                return error;
            }
        },
    },

    mutations: {
        SET_LOGS_ACTIONS(state, data) {
            state.logsActions = data;
        },
        SET_LOADING_LOGS_ACTIONS(state, condition) {
            state.loadingLogsActions = condition;
        },
    },

    getters: {
        GET_LOGS_ACTIONS(state) {
            return state.logsActions?.items;
        },
        GET_LOGS_PAGINATION(state) {
            return state.logsActions?.cursor;
        },
        GET_LOADING_LOGS_ACTIONS(state) {
            return state.loadingLogsActions;
        },
    },
};
