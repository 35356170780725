<template>
  <div>
    <h4>Створено нового водія</h4>
    <p><b>Ім'я: </b>{{ actionData.payload.fullName }}</p>
    <p><b>Телефон: </b>{{ actionData.payload.addedPhone }}</p>
    <p><b>Рахунок: </b>{{ getAccountsTypesTitle(actionData.payload.typeBalance) }}</p>
    <p><b>Можливість самостійно поповнюваті картку: </b>{{ actionData.payload.isAllowSelfPay ? "Так" : "Ні" }}</p>
    <p>
      <b>ID: </b>
      <b-link :href="`/apps/drivers/view/${actionData.payload.newDriverId}`">{{ actionData.payload.newDriverId }}</b-link>
    </p>
  </div>
</template>

<script>

import {BLink} from "bootstrap-vue";

import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";

export default {
  name: "CreateDriver",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getAccountsTypesTitle } = useAccountsTypes();

    return { getAccountsTypesTitle };
  },
};
</script>