import store from "@/store";

import {useStoreModule} from "@/composition/useStoreModule";
import {businessLogsModule} from "@/store/logs/logsStore.module";
import {computed} from "@vue/composition-api";

const STORE_MODULE_NAME = "logsModule";
let fetchParams = {};
function replacer(key, value) {
    if (value === null) {
        return undefined;
    }
    return value;
}
export function useBusinessLogs() {

    const fetchLogsActions = async (params) => {
        fetchParams = {...fetchParams, ...params};
        fetchParams = JSON.parse(JSON.stringify(fetchParams, replacer));
        await store.dispatch(`${STORE_MODULE_NAME}/fetchLogsActions`, fetchParams);
    };

    const logsActions = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_LOGS_ACTIONS`];
    });

    const logsPagination = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_LOGS_PAGINATION`];
    });

    const logsActionsLoading = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_LOADING_LOGS_ACTIONS`];
    });

    useStoreModule(STORE_MODULE_NAME, businessLogsModule);

    return { fetchLogsActions, logsActions, logsPagination, logsActionsLoading };
}