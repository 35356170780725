<template>
  <div>
    <h4>Змінено шаблон знижок</h4>
    <p><b>Призначення: </b>{{ getTargetTypesTitle(actionData.payload.typeApplying) }}</p>
    <p><b>Рахунок: </b>{{ displayAccountTypes(actionData.payload.typeAccounts) }}</p>
    <p><b>Запланована: </b>{{ actionData.payload.isScheduled ? "Так" : "Ні" }}</p>
    <p v-if="actionData.payload.isScheduled"><b>Запланована дата: </b>{{ actionData.payload.scheduledAt }}</p>
    <p>
      <b>Знижка: </b>
      <b-link
          v-if="actionData.payload.discountTemplateId"
          :href="`/apps/discounts/${actionData.payload.discountTemplateId}`">
        {{ getDiscountTemplatesTitle(actionData.payload.discountTemplateId) }}
      </b-link>
      <span v-else>Знижка відсутня</span>
    </p>
    <p v-if="actionData.payload.bundleIds">
      <b>Групи: </b>
      <b-link
          v-for="id in actionData.payload.bundleIds"
          :key="id"
          :href="`/apps/group/view/${id}`">
        {{ getGroupTitle(id) }}
      </b-link>
    </p>
    <p v-if="actionData.payload.excludedBundleIds">
      <b>Виключені групи: </b>
      <b-link
          v-for="id in actionData.payload.excludedBundleIds"
          :key="id"
          :href="`/apps/group/view/${id}`">
        {{ getGroupTitle(id) }}
      </b-link>
    </p>
    <p v-if="actionData.payload.profileIds">
      <b>Водії: </b>
      <b-link
          v-for="id in actionData.payload.profileIds"
          :key="id"
          :href="`/apps/drivers/view/${id}`">
        {{ getDriverName(id) }}
      </b-link>
    </p>
    <p v-if="actionData.payload.excludedProfileIds">
      <b>Виключені водії: </b>
      <b-link
          v-for="id in actionData.payload.excludedProfileIds"
          :key="id"
          :href="`/apps/drivers/view/${id}`">
        {{ getDriverName(id) }}
      </b-link>
    </p>
  </div>
</template>

<script>
import {BLink} from "bootstrap-vue";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { useDiscountTargetTypes } from "@/composition/formOptions/useDiscountTargetTypes";
import {useDiscountTemplatesFetch} from "@/composition/discounts/useDiscountTemplatesFetch";
import {onBeforeMount} from "@vue/composition-api";
import { useDriverGroups } from "@/composition/businessUsers/useDriverGroups";
import { useDrivers } from "@/composition/businessUsers/useDrivers";

export default {
  name: "ChangeDiscountsComposite",
  components: {
    BLink,
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getAccountsTypesTitle } = useAccountsTypes();
    const { getTargetTypesTitle } = useDiscountTargetTypes();
    const { getDiscountTemplatesTitle, fetchAllDiscounts, fetchDiscountTemplates } = useDiscountTemplatesFetch();
    const { getGroupTitle } = useDriverGroups();
    const { getDriverName } = useDrivers();

    function displayAccountTypes(accounts) {
      return accounts.map(t => getAccountsTypesTitle(t.typeAccount)).join(", ");
    }

    onBeforeMount(() => {
      fetchDiscountTemplates();
      fetchAllDiscounts();
    });

    return {
      getAccountsTypesTitle,
      getTargetTypesTitle,
      displayAccountTypes,
      getDiscountTemplatesTitle,
      getGroupTitle,
      getDriverName,
    }
  },
};
</script>